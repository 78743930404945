<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" class="d-flex justify-center">
        <p class="font-weight-bold text-h4">
        Preferences
        </p>
      </v-col>
      <v-col cols="12" sm="12" class="d-flex justify-center">
        soon to come...
      </v-col>
      <v-col cols="12" sm="12" class="d-flex justify-center">
        <v-btn @click="toggleTheme">Toggle Theme </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: "Admin",
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$vuetify.theme.applyTheme()
    }
  }
}
</script>

<style scoped>

</style>